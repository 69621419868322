.page-header {
  padding-top: 16em;
  padding-bottom: 4.5em;
  position: relative;
  @include typeContrast;
  background: $bluePrimary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  .page-header-bug {
    position: absolute;
    left: -$gutterFluid;
    top: -4.6em;
    opacity: 0.1;
    width: 42.5%;
    height: 250%;
    background: transparent url(./images/bug-rb-white.svg) top left no-repeat;
    background-size: 100% auto;
  }
  .row {
    position: relative;
  }
  .bug-division {
    margin-right: 1em;
    display: inline-block;
  }
  .link-breadcrumb {
    @include typeNavLinkTopContrast;
  }
  @media screen and (max-width: $containerFluidMax) {
    .page-header-bug {
      left: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 20em;
    padding-bottom: 0;
    .page-header-bug {
      width: 50%;
    }
    .bug-division {
      display: block;
      margin-bottom: 0.5em;
      &:last-child {
        margin-bottom: 2em;
      }
    }
  }
}

.page-title {
  @include typeHeaderMid;
  @include typeContrast;
  max-width: 46.5%;
  .ul-target {
    position: relative;
    &.active {
      &:after {
        width: 100%;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: calc(100% + #{$typeBase});
      left: 0;
      width: 0;
      height: 0.1em;
      background: $white;
      transition: width 0.66s ease-in-out;
    }
  }
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
  @include media-breakpoint-down(sm) {
    @include typeHeaderEm;
    @include typeContrast;
    max-width: none;
    padding-left: 0;
  }
}
