.program-list__wrapper {
  h2 {
    @include typeHeaderEm;
  }
}

.program-list {
  [class*='col'] {
    &:nth-child(2n-1) {
      padding-right: 5%;
    }
    &:nth-child(2n) {
      padding-left: 5%;
    }
  }
}

.block-program {
  margin-bottom: 10%;
  display: flex;
  flex-wrap: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
  .block-program__image {
    flex: none;
    padding-right: $gutter;
  }
  .block-program__content {
    flex: auto;
    padding-left: $gutter;
  }
  h3 {
    @include typeHeader;
  }
  .read-more {
    @include typeDefaultLarge;
    display: block;
    margin-bottom: 3em;
    text-align: right;
    i {
      margin: 0.5em;
      display: inline-block;
    }
  }
  .block-program__content {
    p {
      margin-bottom: 1.5em;
    }
  }
}
