@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 2000;
  opacity: 0;
  background: rgba($white, 0.95);
  pointer-events: none;
  transition: opacity 0.5s ease-out;
  .admin-bar & {
    height: calc(100vh - 32px);
    top: 32px;
  }
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  .nav-top {
    .nav__menu__link {
      color: $bluePrimary;
    }
  }
}

html.overlay-active {
  &,
  > body {
    overflow: hidden;
  }
}
