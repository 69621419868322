$colPrimaryWidth: calc(
   (0.66666 * #{$contentColumn}) + ((100vw - #{$contentColumn}) / 2)
);
$colSecondaryWidth: calc(
   (0.33333 * #{$contentColumn}) + ((100vw - #{$contentColumn}) / 2)
);

#site-footer {
  background: $bluePrimary;
  z-index: 4;
  position: relative;
  .row {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
  }
  .footer__col {
    padding-top: 4.5em;
    padding-bottom: 4.5em;
  }
  .footer__col-primary {
    order: 2;
    background: $white;
    margin-top: -4em;
    padding-top: 8.5em;
    width: 100%;
    margin-right: $negativeOuterMargin;
    width: $colPrimaryWidth;
    padding-right: $outerMargin;
    padding-left: 5%;
    .footer__subcols {
      display: flex;
      flex-wrap: nowrap;
      .footer__subcol {
        flex: auto;
        padding-right: 5%;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .footer__col-secondary {
    order: 1;
    @include typeContrast;
    border-color: transparent;
    width: $colSecondaryWidth;
    margin-left: $negativeOuterMargin;
    padding-left: $outerMargin;
  }
  .nav-site {
    .nav__menu__item-division {
      display: none;
    }
  }
  .nav__section {
    strong {
      @include typeHeaderNavFooter;
    }
    &.nav__section-team,
    &.nav__section-community {
      .nav__menu {
        columns: 1;
      }
    }
  }
  .nav__menu__link {
    font-size: 0.9em;
  }
  .nav-divisions {
    .nav__section {
      .nav__menu__item {
        @each $name, $div in $divisions {
          &.nav__menu__item-#{$name} {
            .nav__menu__link {
              background: map-get($div, color);
              &:hover {
                background: lighten(map-get($div, color), 5%);
              }
            }
          }
        }
      }
      .nav__menu__link {
        display: block;
        padding: 1em 0;
        color: $white;
        font-weight: 900;
        font-size: 1.5em;
        width: $colSecondaryWidth;
        margin-left: $negativeOuterMargin;
        padding-left: $outerMargin;
        padding-right: 15%;
        &:before {
          content: '';
          background: transparent url(images/bug-rb-white.svg) top left
            no-repeat;
          background-size: contain;
          display: inline-block;
          height: 1.1em;
          width: 1.1em;
          margin-bottom: -0.4em;
        }
        &:hover {
          text-decoration: none;
        }
        span {
          line-height: 1em;
        }
      }
      strong {
        @include typeContrast;
      }
    }
  }
  .nav-social {
    flex-direction: column;
  }
  .bug-footer {
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .footer-copyright {
    width: 100%;
    flex: none;
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    .footer__col-primary {
      padding-right: $gutter;
      margin-right: -1 * $gutter;
      width: 66.666vw;
      .footer__subcols {
        flex-wrap: wrap;
        .footer__subcol {
          width: 100%;
          flex: 100%;
          padding-right: 0;
        }
      }
    }
    .footer__col-secondary {
      padding-left: 0;
      margin-left: -1 * $gutter;
      width: 33.333vw;
    }
    .nav-divisions {
      padding-left: $gutter;
      width: 100%;
      .nav__section {
        width: 100%;
        .nav__menu__link {
          padding-left: $gutter;
          margin-left: -1 * $gutter;
          width: calc(100% + #{$gutter});
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    > .container > .row {
      flex-wrap: wrap;
    }
    .footer__col-primary,
    .footer__col-secondary {
      width: 100%;
      flex: 100%;
      margin-left: -1 * $gutter;
      margin-right: -1 * $gutter;
    }
    .footer__col-primary {
      padding-left: $gutter;
      padding-top: em(70);
      margin-top: 0;
    }
    .footer__col-secondary {
      padding: em(70) 0 0;
    }
    .nav-divisions {
      .nav__section {
        margin: 0;
        .nav__menu__link {
          width: calc(100% + #{$gutter});
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .nav-site-wrapper {
      > * {
        width: 100%;
        flex: 1;
      }
    }
  }
}
