.successstory-list__outer {
  position: relative;
  &.transition {
    height: 300vh;
  }
  &.active,
  &.sticky {
    .successstory-list__inner {
      width: 100%;
    }
    .successstory-list__preheader {
      transform: translateY(-100%);
      &,
      + .row {
        transition: transform 0.3s ease-out;
      }
    }
    .successstory-list__post {
      top: 100vh;
    }
  }
  &.active {
    .successstory-list__inner {
      position: absolute;
      bottom: 0;
    }
  }
  &.sticky {
    .successstory-list__inner {
      position: fixed;
      left: 0;
      bottom: 0;
    }
  }
}

.successstory-list__post {
  height: 2px;
  width: 100%;
  position: relative;
}

.successstory-list__wrapper {
  @extend .container;
  padding-top: 6.666em;
  padding-bottom: 6.666em;
  position: relative;
  .successstory-list__preheader {
    .page-section-header {
      margin-bottom: 0.2em;
    }
    + .row {
      transform: translateY(200%);
    }
  }
  .successstory-list__header {
    h2 {
      @include typeHeaderSuccessStoryBlock;
      transform: translate(calc(-100% + 1em), -100%) rotate(-90deg)
        translateX(-72px);
      transform-origin: 100% 100%;
      white-space: nowrap;
      position: absolute !important;
      &:before {
        font-size: 0.525em;
        transform: rotate(90deg);
        position: absolute;
        left: -2em;
        line-height: 1.6em;
      }
    }
    @include media-breakpoint-down(lg) {
      h2 {
        transform: translate(calc(-100% + 1em), -100%) rotate(-90deg)
          translateX(-72px);
      }
    }
    @include media-breakpoint-down(md) {
      h2 {
        transform: translate(calc(-100% + 1em), -100%) rotate(-90deg)
          translateX(-69px);
      }
    }
    @include media-breakpoint-down(sm) {
      h2 {
        transform: translate(calc(-100% + 1em), -100%) rotate(-90deg)
          translateX(-53px);
      }
    }
  }
  .read-more {
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 3em;
    display: block;
    @include media-breakpoint-down(sm) {
      font-size: 0.66em;
    }
  }
  > .row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
  }
}

.successstory-list {
  position: relative;
  z-index: 2;
  width: 100vw;
  transform: translateX(
    calc(-1 * (((100vw - #{$contentColumn})/2) + 6.75em + #{$gutter * 3}))
  );
  margin: 0;
  padding: 0 0 12px
    calc(((100vw - #{$contentColumn})/2) + 6.75em + #{$gutter * 3});
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.initialized {
    .block-successstory {
      margin: 0 #{$gutter * 2} 0 0;
    }
  }

  $block-successstory-width: 591px;
  $block-successstory-maxWidth: Min(
    $block-successstory-width,
    calc((100vh - 14em) * 0.84)
  );

  .block-successstory {
    width: 50%;
    margin: 0 100vw 0 50vw;
    flex: none;
    max-width: $block-successstory-maxWidth;
    &:nth-child(2) {
      ~ .block-successstory:last-child {
        box-sizing: content-box;
        margin-right: calc(
          100vw - (((100vw - #{$contentColumn})/2) + #{$block-successstory-maxWidth} +
                152px)
        );
      }
    }
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    transform: translateX(-167px);
    padding-left: 167px;
    .block-successstory {
      &:nth-child(2) {
        ~ .block-successstory:last-child {
          margin-right: calc(100vw - (#{$block-successstory-maxWidth} + 167px));
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    transform: translateX(-135px);
    padding-left: 135px;
    .block-successstory {
      &:nth-child(2) {
        ~ .block-successstory:last-child {
          margin-right: calc(100vw - (#{$block-successstory-maxWidth} + 135px));
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    transform: translateX(-119px);
    padding-left: 119px;
    .block-successstory {
      &:nth-child(2) {
        ~ .block-successstory:last-child {
          margin-right: calc(
            100vw - (#{$block-successstory-maxWidth} + #{$gutter})
          );
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    transform: translateX(-64px);
    padding-left: 64px;
  }
  @include media-breakpoint-down(xs) {
    .block-successstory {
      &:nth-child(2) {
        ~ .block-successstory:last-child {
          margin-right: $gutter;
        }
      }
    }
  }
}

.successstory-list__header {
  width: em(152);
  flex: none;
  padding: 0;
  @include media-breakpoint-down(lg) {
    width: em(120);
  }
  @include media-breakpoint-down(md) {
    width: em(104);
  }
  @include media-breakpoint-down(sm) {
    width: em(64);
  }
}

.successstory-list__content {
  flex: auto;
  width: auto;
  margin: 0;
  padding: 0;
  > .row:first-child {
    max-width: calc(100vw - 5.5em);
    max-height: 4.5em;
  }
}

.successstory-list__inner_wrapper {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  padding-right: 0;
  width: auto;
  max-width: none;
  min-width: 100%;
  flex: none;
}

.block-successstory {
  position: relative;
  transition: margin 1s ease-in-out;
  overflow: hidden;
  &.block-card-archive {
    .block__meta {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $bluePrimary;
      z-index: 0;
      @include typeContrast;
      padding: 2em 1.75em;
      blockquote {
        background: none;
        font-weight: normal;
        height: 100%;
        width: 100%;
        margin: 0;
        @include typeHeader;
        @include typeContrast;
      }
    }
  }
}

.block-successstory__image {
  img {
    width: 100%;
    height: auto;
  }
}

.block-successstory__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba($bluePrimary, 0.9);
  @include typeContrast;
  padding: 2em 1.75em;
  pointer-events: none;
  h3 {
    @include typeHeaderSuccessStoryTile;
    @include typeContrast;
  }
  h4 {
    font-size: 1em;
    margin: 0;
    @include arrowLink;
  }
}
