.news-list__wrapper {
  h2 {
    @include typeHeaderEm;
  }
}

.block-news {
  margin-bottom: 10%;
  &.block-news-featured {
    h3 {
      @include typeHeader;
      margin-bottom: 0;
    }
  }
  &.block-card-archive {
    min-height: 25em;
    &,
    .block__content {
      height: 100%;
    }
    .block__content {
      position: relative;
      background: $bluePrimary;
    }
    .block-news__content {
      padding-bottom: calc(3em + #{$grid-gutter-width});
      pointer-events: all;
      a {
        @include typeLinkContrast;
        @include typeContrast;
      }
      h3 {
        @include typeHeaderSmall;
        @include typeContrast;
      }
    }
    .block-news__meta {
      position: absolute;
      bottom: $grid-gutter-width;
      left: $grid-gutter-width;
      width: calc(100% - #{$grid-gutter-width});
    }
    @include media-breakpoint-down(md) {
      min-height: 18em;
    }
  }
  &.block-listitem-archive {
    .block-news__meta {
      font-weight: 400;
      a {
        &:after {
          content: none;
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  h3 {
    @include typeHeaderSmall;
    margin-bottom: 0;
  }
  .read-more {
    @include typeLinkContrast;
    color: $white;
    i {
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 2em;
  }
}

.block-news__meta {
  font-weight: 700;
}

.block-news__content {
  a {
    &,
    &:hover,
    &:active {
      color: $bluePrimary;
    }
  }
}
