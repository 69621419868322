.block-listitem-archive {
  border-bottom: 2px solid $bluePrimary;
  padding: 2em 0;
  .block__title {
    @include typeHeaderSuccessStoryTile;
  }
  .block__subtitle {
    @include typeDefaultLarge;
    font-style: italic;
    margin-bottom: 0.2em;
  }
  a {
    display: block;
    &,
    &.read-more {
      @include typeLink;
      &:hover {
        color: $bluePrimary;
        text-decoration: none;
      }
      &:last-of-type {
        @include arrowLinkBlock($bluePrimary);
      }
    }
    .block__title,
    .block__subtitle {
      color: inherit;
    }
  }
}
