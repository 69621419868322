// ==== STYLES ==== //
/*!
Theme Name: "RiseBoro"
Theme URI:
Author: "Outright"
Description: "Custom theme for RiseBoro website 2020-2021, built by Outright based on the Timber theme"
Version: 1.1.0
*/

// Normalize: make browsers render things more consistently
//@import 'normalize';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';

@import 'variables';
@import 'typography';
@import 'grid';
@import 'base';

// Components: Modular elements styles
@import 'components/overlay';
@import 'components/button';
@import 'components/form';
@import 'components/form_homepage_donate';
@import 'components/form_select_audience';
@import 'components/nav';
@import 'components/page-header';
@import 'components/post-subnav';
@import 'components/header';
@import 'components/footer';
@import 'components/bug-division';
@import 'components/search-services';
@import 'components/archive-card';
@import 'components/archive-listitem';
@import 'components/video';
@import 'components/annualreport-embed';
@import 'components/leap-timeline-embed';
@import 'components/gtranslate';

// Blocks: Reusable blocks
@import 'blocks/image-after-intro';
@import 'blocks/audiences';
@import 'blocks/bushwick';
@import 'blocks/placeholder';
@import 'blocks/nyc';
@import 'blocks/potential';
@import 'blocks/community';
@import 'blocks/join';
@import 'blocks/stat';
@import 'blocks/interrupter';
@import 'blocks/post-intro';
@import 'blocks/post-section';
@import 'blocks/program-list';
@import 'blocks/event-list';
@import 'blocks/news-list';
@import 'blocks/contact-list';
@import 'blocks/successstory-list';
@import 'blocks/housing-list';
@import 'blocks/staff-list';

// Posts: Post types
@import 'posts/single';
@import 'posts/single-custom';
@import 'posts/division';
@import 'posts/program';
@import 'posts/successstory';
@import 'posts/audience';
@import 'posts/event';
@import 'posts/news';
@import 'posts/staff';
@import 'posts/newsletter';

// Pages: Import every page that has its own specific styles
@import 'pages/archive';
@import 'pages/page';
@import 'pages/home';
@import 'pages/successstories';
@import 'pages/search';
@import 'pages/press-room';
@import 'pages/events';
@import 'pages/contact-us';
