.post-type-news {
  .post-intro {
    @extend %breakout;
    max-width: none;
    width: 100vw;
    transform: translateX($gutter);
    @media screen and (max-width: $contentColumnWithGutter) {
      transform: none;
    }
    h1 {
      @include typeHeader;
      max-width: 66.666%;
      padding-right: 10%;
    }
  }
  .post-subsection {
    padding-top: 0;
    background: $white;
  }
}
