.post-type-program {
  .page-header {
    min-height: 36em;
  }
  .page-title {
    margin-top: -0.25em;
  }
  .page-content {
    &.page-content-single-subsection {
      .post-intro {
        padding-bottom: 1.2em;
      }
    }
  }
  .post-subsection {
    padding-top: em(278);
    .post-subsection__bg {
      background-size: cover;
      background-position: center center;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: em(465);
      top: 0;
    }
    .post-subsection__secondary {
      margin-top: em(187);
      background: $blueGrayLight;
    }
    &.post-subsection-single {
      background-position: center bottom em(-112);
      padding-top: 0;
      padding-bottom: em(278);
      .post-subsection__content {
        padding-top: 0;
      }
      .post-subsection__secondary {
        margin-top: 0;
        margin-bottom: em(187);
      }
      .post-subsection__bg {
        top: auto;
        bottom: 0;
      }
    }
    .post-subsection__content {
      img {
        max-width: 45%;
        margin: 0 5% 5% 0;
        display: inline-block;
        height: auto;
        float: left;
      }
      blockquote {
        background: $blueGrayLight;
        padding: 1em;
        @include typeDefaultLarge;
        font-weight: bold;
        margin: 10% 0;
        @include typeContrast;
        &:before {
          content: none;
        }
      }
    }
  }
  .post-section-news-events {
    h2 {
      @include typeContrast;
    }
    .block-event,
    .block-news {
      a {
        @include typeLinkContrast;
        h3 {
          color: inherit;
        }
      }
    }
  }
  .scrollmagic-pin-spacer {
    z-index: 1000;
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    .post-intro,
    .post-intro-img {
      width: 50%;
    }
    .post-intro {
      padding-left: $gutter;
    }
  }
  @include media-breakpoint-down(lg) {
    .post-subsection {
      .post-subsection__bg {
        height: em(278);
      }
      .post-subsection__content {
        order: 1;
      }
      .post-subsection__secondary {
        order: 2;
        padding-bottom: 7.25em;
        margin-top: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .page-header {
      min-height: 0;
    }
    .post-intro,
    .post-intro-img {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
    .post-intro-img {
      padding-left: $gutter;
      height: 360px;
      position: relative;
      img {
        width: calc(360px - #{$gutter});
        max-width: 100%;
        position: absolute;
        bottom: 0;
        left: $gutter;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .post-subsection {
      .post-subsection__content {
        img {
          max-width: 100%;
          margin-right: 0;
          float: none;
          width: 100%;
        }
      }
    }
  }
}
