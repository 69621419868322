.wp-block-potential {
  background: $bluePrimary;
  .wp-block-group__inner-container {
    @extend .container;
    @include typeContrast;
    position: relative;
    padding-top: 20em;
    padding-bottom: 20em;
    overflow: hidden;
    h2,
    figure img {
      transform: translateY(100%);
    }
    h2 {
      @include typeHeaderSuper;
      @include typeContrast;
      position: relative;
      z-index: 2;
    }
    figure {
      position: absolute;
      width: 45%;
      margin: 0;
      &:nth-child(2) {
        left: 0;
        top: 5em;
      }
      &:nth-child(3) {
        right: 0;
        bottom: 5em;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }
}
