.wp-block-join {
  position: relative;
  @include typeAlt;
  min-height: calc(min(100vh, 1348px));
  padding-bottom: 24em;
  > .wp-block-group__inner-container {
    @extend .container;
    padding-top: 5em;
    padding-bottom: 5em;
  }
  figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    margin: 0;
    img {
      width: auto;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: -3vh;
      filter: grayscale(1);
      max-width: none;
    }
  }
  h2 {
    opacity: 1;
    padding-left: (4.5 * $gutter);
    margin-bottom: 2em;
    &.page-section-header {
      margin-bottom: 0.1em;
      padding-left: 0;
      &::before {
        margin-right: calc(1em - #{$gutter}) !important;
        margin-left: -1 * $gutter;
      }
    }
  }

  .wp-block-join__group {
    padding: 0 5%;
    width: 33.333%;
    float: left;
    margin-bottom: 3.75em;
    opacity: 0;
    &:nth-child(3) {
      padding-left: (7.5 * $gutter);
    }
    &:nth-child(5) {
      padding-right: (7.5 * $gutter);
    }
    h3 {
      @include typeHeaderMid;
    }
  }
  #form-homepage-donate {
    @extend .row;
    margin-bottom: 3.75em;
    align-items: center;
    clear: left;
    padding-left: (4.5 * $gutter);
    margin-left: 0;
    margin-right: 0;
    p {
      margin: 0;
    }
    > * {
      @include make-col-ready;
      @include make-col(6);
      &:nth-child(1) {
        padding-left: (7.5 * $gutter + $gutter);
        padding-right: 5%;
      }
      &:nth-child(2) {
        padding-right: (7.5 * $gutter + $gutter);
        padding-left: 5%;
      }
    }
  }
  @media screen and (min-width: 1946px) {
    min-height: 0;
    figure {
      img {
        width: 120%;
        height: auto;
        right: -20%;
        bottom: -14vw;
      }
    }
  }

  @media screen and (max-width: #{$contentColumn + $grid-gutter-width}) {
    h2 {
      padding-left: 5%;
      &.page-section-header {
        padding-left: 5%;
        &:before {
          margin-left: 0;
        }
      }
    }
    #form-homepage-donate {
      padding-left: 5%;
    }
  }
  @include media-breakpoint-down(md) {
    figure {
      img {
        left: -120px;
        transform: translateX(-50%);
        height: 120%;
        bottom: -20%;
      }
    }
    h2,
    #form-homepage-donate {
      margin-bottom: 1em;
      padding-left: $gutter;
      padding-right: $gutter;
      &.page-section-header {
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }
    .wp-block-join__group {
      &,
      &:nth-child(3),
      &:nth-child(5) {
        width: 100%;
        float: none;
        padding-left: $gutter;
        padding-right: $gutter;
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 30em;
    h2 {
      padding-left: 0;
      padding-top: 2em;
      &.page-section-header {
        padding-left: 0;
        padding-top: 0;
        &:before {
          top: 0;
          margin-right: 2em !important;
        }
      }
    }
    .wp-block-join__group {
      &,
      &:nth-child(3),
      &:nth-child(5) {
        padding-left: 0;
      }
    }
    #form-homepage-donate {
      padding-left: 0;
    }
  }
}
