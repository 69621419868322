.block-video {
  margin-bottom: 1em;
  &.block-video-responsive {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
