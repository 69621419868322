.page-events {
  .block-event {
    &.block-card-archive {
      height: 100%;
      .block__image {
        &,
        a {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
      .block__title {
        @include typeHeaderSmall;
        @include typeContrast;
        @include arrowLink;
      }
      .block-event__date {
        span {
          background: none;
          padding: 0;
          display: inline-block;
          font: inherit;
          width: auto;
          @include typeDefault;
          @include typeContrast;
          br {
            display: none;
          }
          &:first-line {
            font: inherit;
            line-height: inherit;
          }
        }
      }
    }
    &.block-listitem-archive {
      .block__date {
        span {
          background: $grayMedium;
          color: $white;
        }
      }
    }
  }
}
