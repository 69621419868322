.wp-block-audiences {
  @extend .container;
  > .wp-block-group__inner-container {
    @extend .row;
    flex-direction: column;
    flex-wrap: wrap;
    > * {
      width: 50%;
      padding: 0 $gutter #{(10 * $gutter)};
      flex: 0 auto;
      margin: 0;
      &:nth-child(2),
      &:nth-child(3) {
        padding-right: (5 * $gutter);
      }
      &:nth-child(1),
      &:nth-child(4) {
        padding-left: (5 * $gutter);
      }
    }
    .wp-block-audiences__content {
      order: 3;
      padding-left: (8 * $gutter);
      h2 {
        &:before {
          position: absolute;
          left: (-10 * $gutter);
        }
      }
    }
    figure {
      order: 4;
      transform: translateY(-20%);
      pointer-events: none;
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 2;
        transform: translateY(-20%);
      }
      &:nth-child(4) {
        transform: translateY(-40%);
      }
      img {
        width: 100%;
        height: auto;
        transform: translateY(200%);
      }
    }
    @include media-breakpoint-down(lg) {
      flex-direction: row;
      > * {
        width: 33.333%;
        &:first-child {
          width: 100%;
          padding: 0 $gutter;
        }
        &:nth-child(2) {
          padding-left: $gutter;
          padding-right: $grid-gutter-width;
          img {
            top: 100%;
          }
        }
        &:nth-child(3) {
          padding-left: $grid-gutter-width;
          padding-right: $grid-gutter-width;
          img {
            top: 50%;
          }
        }
        &:nth-child(4) {
          padding-right: $gutter;
          padding-left: $grid-gutter-width;
        }
      }
      .wp-block-audiences__content {
        order: 0;
      }
      figure {
        img {
          position: relative;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      > * {
        width: 100%;
        padding-bottom: 0;
        & {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
      .wp-block-audiences__content {
        padding-left: $gutter !important;
        padding-right: $gutter !important;
      }
    }
  }
}
