.post-section {
  padding-top: 6em;
  padding-bottom: 6em;
}

.post-section-news-events {
  background: $bluePrimary;
  &,
  h2 {
    @include typeContrast;
  }
  .row [class*='col'] {
    &:first-child {
      padding-right: 5%;
      ~ [class*='col'] {
        padding-left: 5%;
        border-left: 1px solid $white;
      }
    }
    @include media-breakpoint-down(md) {
      &:first-child {
        padding-right: $gutter;
        margin-bottom: 5.5em;
        ~ [class*='col'] {
          padding-left: $gutter;
          border-left: none;
        }
      }
    }
  }
}

.post-subsection {
  background: $bluePrimary top center no-repeat;
  position: relative;
  &.post-subsection-two-thirds {
    .post-subsection__content {
      width: 66.6666vw;
      max-width: 66.6666vw;
    }
    .post-subsection__secondary {
      width: 33.3333vw;
      max-width: 33.3333vw;
    }
    @include media-breakpoint-down(lg) {
      .post-subsection__content,
      .post-subsection__secondary {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .post-subsection__item {
    width: $halfWithSingleOuterMargin;
    transform: none;
    padding-top: em(70);
    padding-bottom: em(116);
    position: relative;
    z-index: 2;
    max-width: 50vw;
    h2 {
      @include typeHeaderEm;
    }
    h3 {
      @include typeHeader;
    }
    .block-contact {
      h3 {
        @include typeDefault;
        font-weight: bold;
        margin: 0;
      }
    }
    @media screen and (max-width: $contentColumnWithGutter) {
      width: 50%;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: none;
      > * {
        max-width: 800px;
      }
    }
  }
  .post-subsection__content {
    @extend %breakout-left;
    background: $white;
    padding-left: $outerMargin;
    padding-right: 5%;
    h2 {
      @include typeHeaderLine;
      &:not(:first-child) {
        margin-top: 1.2em;
      }
    }
    h3 {
      margin: 1.3333em 0 0.666em;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .post-subsection__secondary {
    &:empty {
      display: none;
    }
    @extend %breakout-right;
    background: $white;
    padding-right: $outerMargin;
    padding-left: 5%;
    .contacts-list__wrapper {
      &,
      h3 {
        @include typeContrast;
      }
    }
    .block-contact {
      margin-top: 2em;
      h3 {
        @include typeContrast;
      }
    }
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    .post-subsection__content,
    .post-subsection__secondary {
      padding-left: $gutter;
      padding-right: $gutter;
    }
  }
  @include media-breakpoint-down(lg) {
    .post-subsection__content {
      order: 2;
    }
    .post-subsection__secondary {
      order: 1;
      padding-bottom: 0;
    }
  }
}
