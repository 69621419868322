input[type='text'],
input[type='email'],
input[type='password'],
select {
  appearance: none;
  background: transparent;
  border-radius: 0;
  border: 1px solid $white;
  border-width: 0 0 1px;
  padding: 0;
  &::placeholder {
    color: inherit;
  }
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

form {
  button {
    background: $bluePrimary;
    @include typeContrast;
    border: none;
    border-radius: 0;
  }
}

.page-content {
  #mc_embed_signup {
    form {
      padding: 0;
      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
      }
    }
    .mc-field-group {
      margin-top: 1em;
      &.input-group {
        display: block;
        input {
          display: inline-block;
          margin-right: 0.5em;
        }
        strong {
          &:first-child {
            margin-bottom: 0.5em;
            display: inline-block;
          }
        }
      }
      input[type='text'],
      input[type='email'],
      input[type='password'],
      select {
        border: 1px solid #abb0b2;
        border-radius: 2px;
        width: 100%;
        padding: 8px 0;
        margin: 0;
        text-indent: 2%;
        display: block;
      }
    }
    .mce_inline_error,
    .asterisk {
      background: none;
      color: red;
    }
  }
}
