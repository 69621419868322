.block-card-archive {
  position: relative;
  transition: margin 1s ease-in-out;
  margin-bottom: $grid-gutter-width;
  .block__image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .block__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba($bluePrimary, 0.9);
    @include typeContrast;
    padding: 1em 1.75em;
    pointer-events: none;
  }

  .block__title {
    @include typeHeaderSuccessStoryTile;
    @include typeContrast;
  }
  .block__subtitle {
    font-size: 1em;
    margin: 0;
    @include arrowLink;
    @include typeContrast;
  }
}
