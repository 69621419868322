#form-homepage-donate {
  .form__group {
    border: 2px solid $bluePrimary;
    flex: none !important;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
  }
  select,
  button {
    margin: 0;
  }
  select {
    @include typeFormEm;
    padding: 0.4em 0.75em 0.2em;
    border: none;
  }
  button {
    font-size: 2em;
    width: 2em;
    padding: 0;
  }
}
