.post-subnav__wrapper {
  background: $bluePrimary;
  @include typeContrast;
  z-index: 1000;
  &.sticky {
    inset: 0 auto auto 0 !important;
    width: 100% !important;
  }
  &.reduced {
    min-height: 0;
    .post-subnav__nav {
      font-size: 0.8em;
      padding: em(50) 0 em(24);
      .row {
        &.row-dense {
          a {
            font-size: 1.35em;
          }
        }
      }
    }
  }
}
.post-subnav__nav {
  padding: em(80) 0;
  transition: padding 0.25s;
  .row {
    &.row-dense {
      a {
        font-size: 1.2em;
      }
    }
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  ul {
    margin: 0 -#{$gutter};
    align-items: center;
  }
  li {
    padding: 0 #{$gutter};
    flex: 1;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  a {
    @include typeNavPostSub;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 4em;
    font-size: 0.8em;
    &.reduced {
      font-size: 1em;
    }
    ul {
      justify-content: flex-start;
      align-items: flex-start;
    }
    li {
      flex: 1;
      width: auto;
      min-width: 25%;
      &,
      &:first-child,
      &:last-child {
        text-align: left;
      }
    }
    a {
      padding-bottom: 1em;
      display: inline-block;
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
