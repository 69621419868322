#form-select-audience {
  display: block;
  width: 100%;
  select,
  .drum-drum,
  .drum-viewport {
    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }
  select,
  .drum-viewport {
    @include typeFormEm;
  }
  .drum-viewport {
    box-shadow: inset 0px 25px 20px #fff, inset 0px -25px 20px #fff;
    border: 1px solid transparent;
    height: 3.6em;
    margin-left: -20px;
    width: calc(100% + 40px);
    &:focus {
      border-color: #fff;
    }
    @include media-breakpoint-down(md) {
      box-shadow: inset 0px 20px 15px #fff, inset 0px -20px 15px #fff;
    }
    @include media-breakpoint-down(sm) {
      box-shadow: inset 0px 15px 10px #fff, inset 0px -15px 10px #fff;
    }
  }
  .drum-drum {
    z-index: -1;
    .drum-item,
    .drum-item-current {
      padding: 0.1em 20px;
      line-height: 1.1em;
    }
  }
  label {
    font-weight: bold;
    display: inline-block;
    background: $bluePrimary;
    color: $white;
    padding: 0.5em 1em;
    line-height: 1em;
    font-size: 1.1em;
  }
  .form-group {
    display: flex;
    justify-items: center;
    margin-top: 1em;
  }
  button {
    color: $bluePrimary;
    background: none;
    font-size: 2em;
    padding: 0;
    margin: 0 0 0 auto;
  }
  @include media-breakpoint-down(lg) {
    .drum-viewport {
      width: auto;
    }
    button {
      margin: 0;
      width: 3em;
    }
  }
}
