.wp-block-homepage-image-after-intro {
  &,
  &.wp-block-image {
    margin: -10em 0 10em;
  }
  @extend .container;
  img {
    @extend %breakout-left;
    margin-left: $negativeOuterMargin;
    padding-left: 0;
    height: auto;
    max-width: none !important;
    filter: grayscale(1);
    @media screen and (max-width: $contentColumnWithGutter) {
      width: auto;
      padding-right: 0;
      margin-left: #{-1 * $gutter};
    }
    @include media-breakpoint-down(md) {
      width: calc(100% + #{$grid-gutter-width});
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 5em !important;
  }
}
