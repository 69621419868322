.page-content {
  .archive {
    padding-top: em(70);
    padding-bottom: em(116);
    h2 {
      @include typeHeaderEm;
      @include typeHeaderLine;
    }
  }
}
