.post-intro,
.post-intro-img {
  max-width: 50vw;
}
.post-intro {
  padding-right: 5%;
  padding-top: 2.5em;
  padding-bottom: 5em;
}
.post-intro-img {
  position: relative;
  padding-left: 5%;
  img {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 2.5s ease-in-out;
    bottom: 0;
    &.active {
      opacity: 1;
    }
  }
  .post-intro-img__wrapper {
    position: relative;
    width: 100%;
    top: 100%;
  }
  @include media-breakpoint-down(md) {
    img {
      position: static;
    }
    .post-intro-img__wrapper {
      position: static;
    }
  }
}
@include media-breakpoint-down(lg) {
  .post-intro,
  .post-intro-img {
    max-width: none;
  }
}
@include media-breakpoint-down(sm) {
  .post-intro {
    padding-right: $gutter;
  }
  .post-intro-img {
    padding-left: $gutter;
  }
}
