.page-lives-changed {
  .post-subsection__content,
  .post-subsection__secondary {
    width: calc(50% + ((100vw - #{$contentColumn}) / 2));
  }
  .page-title {
    max-width: none;
  }
  .page-content {
    .post-subsection__content {
      padding-bottom: 5em;
      h2 {
        padding-bottom: 0;
        @include typeHeader;
        &:after {
          content: none;
        }
        + p {
          padding-bottom: 0;
          margin-bottom: 0;
          &:after {
            content: '';
            display: block;
            width: 3em;
            height: 4px;
            background: $blueGrayLight;
            margin-top: 3.5em;
          }
        }
      }
    }
  }
  .successstories-archive {
    position: relative;
    top: -17em;
    margin-bottom: em(340);
    .row {
      margin-bottom: -45%;
    }
    .col-sm-6 {
      margin-bottom: 10%;
      &:nth-child(odd) {
        padding-right: 5%;
        transform: translateY(-50%);
      }
      &:nth-child(even) {
        padding-left: 5%;
      }
    }
    .block-successstory {
      .block__image {
        transform: translateY(100%);
      }
      blockquote {
        padding: 0 0 0 2em;
        margin: 2em 0 0;
        &:before {
          font-size: 3em;
        }
      }
    }
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    .post-subsection__content,
    .post-subsection__secondary {
      width: 50%;
    }
  }
  @include media-breakpoint-down(lg) {
    .successstories-archive {
      //top:0;
      .block-successstory {
        .block__image {
          transform: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .post-subsection__content,
    .post-subsection__secondary {
      width: 100%;
    }
    .successstories-archive {
      top: 0;
      .col-sm-6 {
        &:nth-child(odd) {
          transform: none;
        }
        &,
        &:nth-child(odd),
        &:nth-child(even) {
          padding: 0 $gutter 0;
          margin-bottom: $grid-gutter-width;
        }
      }
      .block-successstory {
        .block__image {
          transform: translateY(100%);
        }
        blockquote {
          margin-top: 0;
        }
      }
    }
  }
}
