@each $name, $div in $divisions {
  .post-type-division-#{$name} {
    .page-header-division {
      background: map-get($div, color);
    }
    .post-section-stats {
      background: map-get($div, color);
    }
    .block-program {
      .read-more {
        i {
          color: map-get($div, color);
        }
      }
    }
    .post-section-news-events {
      background: map-get($div, color);
    }
  }
}

.post-type-division {
  .page-header {
    .page-title {
      @include typeHeaderSuper;
      @include typeContrast;
      margin-top: -0.25em;
      margin-bottom: 0.25em;
      max-width: none;
      @include media-breakpoint-down(sm) {
        font-size: em(48);
      }
    }
    .page-header-bug {
      height: calc(220% + (3 * #{$typeBase}));
    }
  }
  .post-intro {
    min-height: 32em;
  }
  &.post-type-division-community {
    .block-stat {
      strong {
        @include typeContrast;
      }
    }
    .post-section-news-events {
      a {
        @include typeLinkContrast;
      }
    }
    .event-list__wrapper,
    .news-list__wrapper {
      h2 {
        @include typeContrast;
      }
    }
    .block-event,
    .block-news {
      a {
        @include typeLinkContrast;
      }
      h3 {
        @include typeContrast;
      }
    }
  }
  .post-section-stats {
    .block-stat {
      @include make-col-ready();
      width: auto;
      flex: 1;
      @include typeContrast;
      border-left: 1px solid $white;
      &:first-child {
        border-left: none;
      }
    }
  }
  .post-section-contact {
    background: $bluePrimary;
    &,
    h2 {
      @include typeContrast;
    }
    a {
      @include typeLinkContrast;
    }
  }
  @include media-breakpoint-down(md) {
    .page-header {
      min-height: 0;
      padding-bottom: em(36);
    }
    .post-intro,
    .post-intro-img {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
    .post-intro {
      min-height: 0;
    }
    .post-intro-img {
      padding-left: $gutter;
      position: relative;
      height: 360px;
      img {
        width: 360px;
        max-width: calc(100% - #{$grid-gutter-width});
        position: absolute;
        left: $gutter;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .post-section-stats {
      .row {
        flex-wrap: wrap;
      }
      .block-stat {
        width: 100%;
        flex: none;
        margin-bottom: 4em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
