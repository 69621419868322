.footer-interrupter,
.precontent-interrupter {
  height: 40vw;
  max-height: 42.4em;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.footer-interrupter {
}

.precontent-interrupter {
  max-height: 24em;
  img {
    top: auto;
    bottom: 0;
    transform: translateX(-50%);
  }
}
