.page-press {
  .page-title {
    max-width: none;
  }
  .archive-press-featured {
    [class*='col-'] {
      margin-bottom: $grid-gutter-width;
    }
  }
}
