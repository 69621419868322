.wp-block-placeholder {
  > div {
    @extend %breakout;
    padding: #{2 * $gutter} calc((100vw - #{$contentColumn}) / 2);
    background: #eee;
    height: 600px;
    align-content: center;
    color: #999;
  }
}
