.wp-block-community {
  background: rgba($bluePrimary, 0.75);
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  z-index: 2;
  .wp-block-group__inner-container {
    @extend .container;
    @include typeContrast;
    position: relative;
    padding-top: 5em;
    padding-bottom: 5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    h2,
    p {
      padding-left: (7.5 * $gutter);
      padding-right: 10%;
    }
    h2 {
      @include typeContrast;
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      position: relative;
      &:before {
        position: absolute;
        content: '\2e3a';
        left: 0;
      }
    }
    p {
      grid-column-start: 1;
      grid-row-start: 2;
    }
    form {
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 2;
      padding-left: 10%;
    }
    input {
      @include typeHeader;
      @include typeContrast;
    }
    @include media-breakpoint-down(md) {
      display: block;
      form {
        margin-top: 2em;
        padding-left: (7.5 * $gutter);
        input {
          display: block;
          width: 100%;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      h2,
      p {
        padding-left: 0;
        padding-right: 0;
      }
      h2 {
        padding-top: 2em;
        &:before {
          top: 0;
        }
      }
      form {
        padding-left: 0;
      }
    }
  }
}
