// Colors
$black: #000000;
$white: #ffffff;

$grayMedium: #aaa;
$grayLightest: #f3f5f7;

$bluePrimary: #003594;
$blueLight: #419fdb;
$blueGray: #576886;
$blueGrayLight: #9cafb7;

$purplePrimary: #a05eb5;
$greenPrimary: #45ad70;
$redPrimary: #e73a28;
$yellowPrimary: #ffa300;

//Divisions
$divisions: (
  'seniors': (color:$purplePrimary),
  'housing': (color:$blueLight),
  'education': (color:$greenPrimary),
  'health': (color:$redPrimary),
  'empowerment': (color:$yellowPrimary),
  'community': (color:$bluePrimary)
);

@mixin arrowLink($color: #fff) {
  &:after {
    display: inline-block;
    content: '';
    background-image: url(#{'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="' +
      $color +
      '" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/></svg>'});
    background-repeat: no-repeat;
    background-size: cover;
    height: 1.2em;
    width: 1.2em;
    vertical-align: text-bottom;
    fill: red;
  }
}

@mixin arrowLinkBlock($color: #fff) {
  @include arrowLink($color);
  display: block;
  position: relative;
  padding-right: 2em;
  &:after {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5em;
  }
  &:hover {
    &:after {
      display: block;
    }
  }
}
