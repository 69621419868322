.btn {
  &.btn-icon {
    border: 1px solid $bluePrimary;
    border-radius: 0;
    padding: 0.33em 3.5em 0.33em 0.5em;
    color: $bluePrimary;
    font-weight: bold;
    position: relative;
    text-align: center;
    display: inline-block;
    transition: background 0.25s ease-in-out;
    &.btn-icon-alt {
      background: $bluePrimary;
      color: $white;
      &:hover {
        color: $white;
        background-color: $blueLight;
        text-decoration: none;
      }
    }
    &:hover {
      color: $blueLight;
      border-color: $blueLight;
      i {
        background-color: $blueLight;
      }
    }
    i {
      background: $bluePrimary;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: auto;
      width: auto;
      display: flex;
      align-content: center;
      justify-content: center;
      width: 3em;
      font-size: 1em;
      vertical-align: middle;
      transition: background 0.25s ease-in-out;
      &:before {
        line-height: 1.9em;
        vertical-align: middle;
      }
    }
  }
  &.btn-primary {
    background: $bluePrimary;
    border: none;
    border-radius: 0;
    &:hover {
      backrgound: $blueLight;
    }
  }
}

.button {
  @extend .btn;
  @extend .btn-primary;
}
