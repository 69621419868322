.post-type-custom-single {
  .post-intro,
  .post-intro-img {
    width: $halfWithSingleOuterMargin;
    transform: none;
  }
  .post-intro {
    background: $grayLightest;
    padding-top: em(70);
    padding-bottom: em(70);
    padding-left: $outerMargin;
    min-height: 20em;
    h1 {
      @include typeHeaderMidReduced;
      margin-bottom: 0.2em;
    }
    h2,
    h3 {
      @include typeHeader;
      margin-bottom: 0.6em;
    }
    h2 {
      font-style: italic;
    }
    h1,
    h2,
    h3 {
      line-height: 1.2em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .bug-division {
      display: inline-block;
      margin-right: 1em;
    }
  }
  .post-intro-img {
    background: $grayLightest;
    padding-right: $outerMargin;
  }
  .post-subsection__item {
    &.post-subsection__secondary {
      padding-left: calc(#{em(128)} + 5%);
      h2 {
        @include typeHeader;
        position: relative;
        margin-top: 3em;
        &:first-child {
          margin-top: 0;
        }
        &:before {
          content: '';
          height: 4px;
          width: 50px;
          left: calc(-128px);
          top: 0.5em;
          position: absolute;
          background: $bluePrimary;
        }
      }
      .block-contact {
        h3 {
          color: inherit;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      &.post-subsection__secondary {
        padding-left: $gutter;
        h2 {
          padding-top: 2.5em;
          &:before {
            left: 0;
            top: 1em;
          }
        }
      }
    }
  }
  @media screen and (max-width: #{$contentColumnWithGutter + 15px}) {
    .post-intro,
    .post-intro-img {
      width: 50%;
    }
    .post-intro {
      padding-left: $gutter;
    }
  }
  @include media-breakpoint-down(md) {
    .post-intro,
    .post-intro-img {
      width: 100%;
      max-width: none;
    }
    .post-intro {
      min-height: 0;
    }
    .post-intro-img {
      background: $grayLightest;
      width: 100%;
      padding-left: $gutter;
      img {
        width: 360px;
        max-width: 100%;
      }
    }
  }
}
