.housing-list {
  margin-top: em(40);
  .block-housing {
    @extend .block-card-archive;
    .block__image {
      &,
      a {
        display: block;
        height: 100%;
        width: 100%;
      }
      img {
        max-width: none !important;
        margin: 0 !important;
        float: none !important;
      }
    }
    .block__title {
      @include typeDefault;
      font-weight: bold;
      @include typeContrast;
      margin: 0;
    }
    .block__content {
      padding: 1em 1.25em;
      min-height: 4.9em;
    }
  }
}
