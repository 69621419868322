.event-list__wrapper {
  h2 {
    @include typeHeaderEm;
  }
}

.block-event {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10%;
  &:last-child {
    margin-bottom: 0;
  }
  &.block-event-past {
    .block-event__date {
      span {
        color: $grayMedium;
      }
    }
  }
  h3 {
    @include typeHeader;
  }
  .block-event__content {
    flex: auto;
  }
  .block-event__date {
    flex: none;
    padding-right: $gutter;
    span {
      display: block;
      background: $white;
      color: $bluePrimary;
      text-align: center;
      padding: 0.2em 0 0;
      font-weight: 900;
      font-size: 2.4em;
      line-height: 1.4em;
      width: 2em;
      &:first-line {
        font-weight: 700;
        font-size: 0.625em;
        line-height: 1em;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .read-more {
    @include typeLinkContrast;
    color: $white;
    i {
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(sm) {
    .block-event__date {
      > a {
        padding-right: 0;
      }
      span {
        font-size: 2em;
      }
    }
  }
}

.block-event__meta {
  font-weight: 700;
  a {
    @include typeLinkContrast;
  }
}

.block-event__content {
  padding-left: $gutter;
  a {
    &,
    &:hover,
    &:active {
      color: $bluePrimary;
    }
  }
}
.block-event__description {
  margin-bottom: 0;
}
