// home page styles
.home {
  .page-header {
    background: $bluePrimary;
    height: 100vh;
    padding-bottom: 15em;
    padding-top: 14em;
    max-height: 1200px;
    &.initialized {
      > * {
        opacity: 1;
      }
    }
    > * {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
    .row {
      position: relative;
    }
    .page-header-bug {
      top: -#{$gutterFluid};
    }
    @include media-breakpoint-down(lg) {
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 18em;
    }
  }
  .page-title-wrapper {
    width: 66.666%;
    padding-left: $gutter;
    padding-right: $gutter;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .page-title {
    @include typeHeaderSuper;
    @include typeContrast;
    line-height: 1em;
    max-width: none;
    padding: 0;
    word-wrap: normal;
    word-break: normal;
    overflow-wrap: normal;
  }
  .page-content {
    counter-reset: section;
    .wp-homepage-header-numbered {
      position: relative;
      &:before {
        counter-increment: section;
        content: counter(section, decimal-leading-zero) '. ';
        font-size: 0.25em;
        margin-right: 2em;
        display: inline-block;
        line-height: 4em;
        vertical-align: top;
        margin-top: -0.6em;
      }
    }
  }
  .page-section-header {
    @extend .container;
    @include typeHeaderSuperEm;
    margin: 0 auto 1em;
  }
}
