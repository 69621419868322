.bug-division {
  &.bug-division-contrast {
    a {
      &:before {
        color: $white;
        border-color: $white;
      }
    }
  }
  a {
    text-decoration: none;
    line-height: 1em;
    font-weight: bolder;
    &:hover {
      text-decoration: none;
      border-width: 0 0 0.2em;
      border-style: solid;
    }
    &:before {
      content: 'RB ';
      border-bottom: 0.2em solid $bluePrimary;
      color: $bluePrimary;
      margin-bottom: -0.2em;
      margin-top: 0.2em;
      display: inline-block;
      margin-right: 0.2em;
    }
  }
}

@each $name, $div in $divisions {
  .bug-division-#{$name} {
    &,
    a {
      &,
      &:hover,
      &:active {
        color: map-get($div, color);
      }
    }
    a {
      border-color: map-get($div, color);
    }
  }
}

.bug-division-community {
  &,
  a {
    &,
    &:hover,
    &:active {
      color: $bluePrimary;
    }
  }
  a {
    border-color: $bluePrimary;
  }
  &.bug-division-contrast {
    &,
    a {
      &,
      &:hover,
      &:active {
        color: $white;
      }
    }
    a {
      border-color: $white;
      &:before {
        color: $white;
        border-color: $white;
      }
    }
  }
}
