//Layout
$contentColumn: 1300px;
$gutter: $grid-gutter-width / 2;
$gutterFluid: 35px;
$contentColumnWithGutter: $contentColumn + $grid-gutter-width;
$containerFluidMax: $contentColumn + (4 * $gutter) + (2 * $gutterFluid);

.container {
  max-width: $contentColumnWithGutter;
  width: 100%;
}

.container-fluid {
  max-width: $containerFluidMax;
}

$breakoutBreakpoint: $contentColumn;
$outerMargin: calc((100vw - #{$contentColumn}) / 2);
$outerMarginMinusGutter: calc((100vw - #{$contentColumn}) / 2 - #{$gutter});
$outerPadding: calc((100vw - #{$contentColumn}) / 2 + #{$gutter});
$negativeOuterMargin: calc(-1 * ((100vw - #{$contentColumn}) / 2));
$negativeOuterPadding: calc(
  -1 * ((100vw - #{$contentColumn}) / 2 - #{$gutter})
);
$widthWithSingleOuterMargin: calc(
  100% + ((100vw - #{$contentColumn}) / 2 + #{$gutter})
);
$halfWithSingleOuterMargin: calc(50% + ((100vw - #{$contentColumn}) / 2));

$adjustedOuterMargin: calc((100vw - #{$contentColumn}) / 2 + #{$gutter});

%breakout {
  width: 100vw;
  margin-left: $negativeOuterMargin;
  margin-right: $negativeOuterMargin;
  padding-left: $outerMarginMinusGutter;
  padding-right: $outerMarginMinusGutter;
  @media screen and (max-width: $contentColumnWithGutter) {
    margin-left: 0;
    margin-right: 0;
    padding-left: $gutter;
    padding-right: $gutter;
  }
}
%breakout-left {
  width: $widthWithSingleOuterMargin;
  margin-left: $negativeOuterMargin;
  padding-left: $outerMargin;
  margin-left: $negativeOuterPadding;
  @media screen and (max-width: $contentColumnWithGutter) {
    width: 50%;
    margin-left: 0;
    padding-left: $gutter;
    transform: none;
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
    padding-left: $gutter;
    padding-right: $gutter;
  }
}
%breakout-right {
  width: $widthWithSingleOuterMargin;
  margin-right: $negativeOuterMargin;
  padding-right: $outerMargin;
  margin-right: $negativeOuterPadding;
  @media screen and (max-width: $contentColumnWithGutter) {
    width: 50%;
    margin-right: 0;
    padding-right: $gutter;
    transform: none;
  }
  @include media-breakpoint-down(lg) {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

.breakout {
  @extend %breakout;
}
.breakout-left {
  @extend %breakout-left;
}
.breakout-right {
  @extend %breakout-right;
}
