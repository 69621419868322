@import url('//hello.myfonts.net/count/346649');

@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  src: url('fonts/346649_0_0.eot');
  src: url('fonts/346649_0_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/346649_0_0.woff2') format('woff2'),
    url('fonts/346649_0_0.woff') format('woff'),
    url('fonts/346649_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: url('fonts/346649_1_0.eot');
  src: url('fonts/346649_1_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/346649_1_0.woff2') format('woff2'),
    url('fonts/346649_1_0.woff') format('woff'),
    url('fonts/346649_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 900;
  src: url('fonts/346649_2_0.eot');
  src: url('fonts/346649_2_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/346649_2_0.woff2') format('woff2'),
    url('fonts/346649_2_0.woff') format('woff'),
    url('fonts/346649_2_0.ttf') format('truetype');
}

$typeBase: 16px;
$fontBase: 'Gilroy', sans-serif;

@function em($px, $base: 16) {
  @return ($px / $base) * 1em;
}

@mixin typeDefault {
  font-family: $fontBase;
  color: $bluePrimary;
  font-size: $typeBase;
  line-height: 1.45em;
}

@mixin typeContrast {
  color: $white;
}

@mixin typeAlt {
  color: $blueGray;
}

@mixin typeDefaultLarge {
  font-family: $fontBase;
  color: $bluePrimary;
  font-size: em(19.2);
  line-height: 1.45em;
}

@mixin typeHeader {
  font-family: $fontBase;
  font-weight: 900;
  color: $bluePrimary;
  line-height: 1.22em;
  font-size: em(36.8);
  margin-bottom: em(24, 36.8);
  @include media-breakpoint-down(md) {
    font-size: em(30);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(24);
  }
}

@mixin typeHeaderSmall {
  @include typeHeader;
  font-size: em(24);
  margin: 0;
  padding: 0;
  @include media-breakpoint-down(md) {
    font-size: em(18);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(17);
  }
}

@mixin typeHeaderSearchResult {
  @include typeHeader;
  font-size: em(24);
  margin: 0;
  padding: 0 0 0.5em;
  @include media-breakpoint-down(md) {
    font-size: em(18);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(17);
  }
}

@mixin typeHeaderEm {
  @include typeHeader;
  font-size: em(48);
  margin-bottom: 1.666em;
  @include media-breakpoint-down(md) {
    font-size: em(40);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(32);
  }
}

@mixin typeHeaderMidReduced {
  font-size: em(72);
  margin-bottom: 0;
  line-height: 1em;
  @include media-breakpoint-down(md) {
    font-size: em(64);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(56);
  }
}

@mixin typeHeaderMid {
  @include typeHeader;
  font-size: em(80);
  margin-bottom: em(16.5, 84);
  @include media-breakpoint-down(md) {
    font-size: em(66);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(40);
  }
}

@mixin typeHeaderSuperReduced {
  @include typeHeader;
  font-size: em(116);
  margin: 0;
  @include media-breakpoint-down(lg) {
    font-size: em(90);
  }
  @media screen and (max-height: 800px) {
    font-size: em(90);
  }
  @include media-breakpoint-down(md) {
    font-size: em(60);
  }
  @media screen and (max-height: 500px) {
    font-size: em(60);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(32);
  }
}

@mixin typeHeaderSuper {
  @include typeHeader;
  font-size: em(140);
  margin: 0;
  @include media-breakpoint-down(md) {
    font-size: em(100);
  }
  @media screen and (max-height: 500px) {
    font-size: em(100);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(60);
  }
}

@mixin typeHeaderSuperEm {
  @include typeHeader;
  font-size: em(150);
  margin: 0;
  line-height: 1em;
  @include media-breakpoint-down(lg) {
    font-size: em(120);
  }
  @media screen and (max-height: 800px) {
    font-size: em(120);
  }
  @include media-breakpoint-down(md) {
    font-size: em(90);
  }
  @media screen and (max-height: 500px) {
    font-size: em(90);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(48);
  }
}

@mixin typeHeaderTight {
  margin-bottom: 0.666em;
}

@mixin typeHeaderLine {
  &:after {
    content: '';
    display: block;
    width: 1.333em;
    height: 4px;
    background: $blueGrayLight;
    margin-top: 0.666em;
  }
  margin-bottom: 1em;
}

@mixin typeHeaderSuccessStoryBlock {
  @include typeHeader;
  font-size: Min(#{em(72)}, calc(0.125 * (100vh - 14em)));
  margin-bottom: 0;
  line-height: 1em;
  @include media-breakpoint-down(lg) {
    font-size: Min(#{em(64)}, calc(0.125 * (100vh - 14em)));
  }
  @include media-breakpoint-down(md) {
    font-size: Min(#{em(49)}, calc(0.125 * (100vh - 14em)));
  }
  @include media-breakpoint-down(sm) {
    font-size: Min(#{em(40)}, calc(0.125 * (100vh - 14em)));
  }
}

@mixin typeHeaderSuccessStoryTile {
  @include typeHeader;
  font-size: em(28.8);
  margin-bottom: 0;
  line-height: 1.2em;
}

@mixin typeHeaderNav {
  font-family: $fontBase;
  font-weight: 900;
  color: $bluePrimary;
  line-height: 1.1em;
  font-size: em(45);
  margin-bottom: em(24, 45);
  display: block;
}

@mixin typeHeaderNavFooter {
  @include typeHeaderNav;
  font-size: em(30);
}

@mixin typeLink {
  font-family: $fontBase;
  color: $bluePrimary;
  transition: all 0.25s ease;
  &:hover {
    color: $blueLight;
    text-decoration: underline;
  }
}

@mixin typeNavLinkTop {
  @include typeLink;
  font-size: em(17);
  font-weight: 600;
  line-height: 1.2em;
}

@mixin typeNavLinkTopContrast {
  @include typeNavLinkTop;
  color: $white;
}

@mixin typeLinkContrast {
  color: $blueLight;
  &:hover {
    color: $white;
  }
}

@mixin typeNavPostSub {
  font-family: $fontBase;
  font-size: em(25);
  transition: all 0.25s ease;
  font-weight: 700;
  line-height: 1.2em;
  color: $white;
  &:hover,
  &.active {
    color: $blueLight;
    text-decoration: underline;
  }
}

@mixin typeFormEm {
  font-family: $fontBase;
  color: $bluePrimary;
  font-size: em(30);
  font-weight: 800;
  line-height: 1em;
  @include media-breakpoint-down(md) {
    font-size: em(24);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(18);
  }
}

@mixin typeStatisticValue {
  font-family: $fontBase;
  color: $bluePrimary;
  font-size: em(96);
  font-weight: 900;
  line-height: 1em;
  margin-bottom: 0.3em;
  @include media-breakpoint-down(md) {
    font-size: em(84);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(72);
  }
}

.header-icon {
  vertical-align: middle;
  &.header-icon-alert {
    &:before {
      content: '!';
    }
  }
  &.header-icon-question {
    &:before {
      content: '?';
    }
  }
  &:before {
    font-size: 1.5em;
    background: #003594;
    height: 1.3em;
    width: 1.3em;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    color: #fff;
    line-height: 1.5em;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 1.3em;
  }
}
