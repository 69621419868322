#site-header {
  position: absolute;
  width: 100%;
  z-index: 2000;
  .container-fluid {
    padding-top: $gutterFluid + 26px;
    padding-bottom: $gutterFluid;
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }
  .nav-site-wrapper {
    @include overlay;
    .container {
      padding-top: $gutterFluid;
      padding-bottom: 0;
      overflow: auto;
    }
    .nav-top {
      margin-top: 4.6em;
    }
    @include media-breakpoint-down(md) {
      .container {
        padding-top: 0;
      }
    }
  }
  .nav-site {
    @include media-breakpoint-down(md) {
      .nav__section {
        strong {
          @include typeHeaderNavFooter;
        }
      }
    }
  }
}
