.search-services-wrapper {
  @include overlay;
  display: flex;
  > div {
    &:first-child {
      flex: none;
      height: auto;
    }
    &:last-child {
      flex: 1;
      min-height: 0;
      > .row,
      > .row > [class*='col'],
      .searchform {
        height: 100%;
        overflow: hidden;
      }
    }
  }
  .searchform {
    @include typeHeaderEm;
    margin: 0;
    &.has-autosuggest {
      + .nav-services {
        display: none;
      }
    }
    > div {
      height: 100%;
    }
    input[type='search'] {
      flex: none;
      height: auto;
    }
    .search-additional-fields {
      display: none;
    }
    .ep-autosuggest-container {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      padding-top: 1em;
      > .ep-autosuggest {
        position: static;
        flex: 1;
        min-height: 0;
        overflow: auto;
        padding-bottom: em(48);
      }
    }
    button[type='submit'] {
      bottom: auto;
      top: 3.5em;
    }
  }
}

.searchform .ep-autosuggest-container .ep-autosuggest,
.search-services-wrapper .nav-services {
  background: none;
  box-shadow: none;
  font-size: 0.45em;
  border: none;
  font-family: inherit;
  .autosuggest-list,
  ul {
    margin: 0;
    padding: 0;
    .autosuggest-item,
    li {
      line-height: 1em;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $bluePrimary;
      .autosuggest-link,
      a {
        color: $bluePrimary;
        padding: 1.2em 0;
        &:hover {
          background: none;
          color: $blueLight;
        }
      }
    }
  }
}

.search-services-wrapper .nav-services {
  position: absolute;
  top: 6em;
  bottom: 0;
  height: auto;
  overflow: auto;
  width: 100%;
  font-size: 1.4em;
  z-index: 2;
  ul {
    display: block;
    width: calc(100% - #{$grid-gutter-width});
    padding-bottom: 3em;
    li {
      &:first-child {
        display: none;
      }
      a {
        font-weight: bold;
        font-size: 1em;
        line-height: 0.85em;
      }
    }
  }
}

.searchform {
  position: relative;
  button[type='submit'] {
    position: absolute;
    background: none;
    border: none;
    color: $bluePrimary;
    bottom: 0.6em;
    right: 0;
    font-size: 0.4em;
    font-weight: bold;
  }
  input[type='search'] {
    appearance: none;
    border: none;
    border-bottom: 0.1em solid $bluePrimary;
    padding: 0.25em 1em 0.25em 0;
    display: block;
    width: 100%;
    background: none;
    border-radius: 0;
    box-shadow: none;
    color: $bluePrimary;
    font-weight: bold;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $blueGrayLight;
    }
  }
  .ep-highlight {
    font-weight: inherit;
    font-style: inherit;
  }
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
