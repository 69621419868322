.wp-block-nyc {
  height: 300vh;
  .wp-block-group__inner-container {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;
    }
  }
  figure {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: -50vh;
      left: 0;
      @media screen and (orientation: portrait) {
        top: -50vw;
        width: auto;
        height: 200%;
        max-width: none;
      }
    }
  }
  h2 {
    transform-origin: 50% 0.61em;
    @include typeHeaderSuper;
    @include typeContrast;
    position: absolute;
    z-index: 2;
    left: calc((100vw - #{$contentColumn}) / 2);
    bottom: -100%;
    width: $contentColumn;
    @media screen and (max-width: $contentColumnWithGutter) {
      left: $gutter;
      width: calc(100% - #{$grid-gutter-width});
    }
  }
}
