.page-services-search {
  .ep-highlight {
    font-weight: inherit;
    font-style: inherit;
    background: yellow;
    display: inline-block;
  }
  .page-controls {
    background: $grayLightest;
    padding: 1em 0;
    div[class*='col-'] {
      //border-right: 1px solid $blueGrayLight;
      padding-right: 40px;
      padding-left: 40px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      &:first-child {
        padding-left: 15px;
      }
    }
  }
  .searchform {
    font-size: 1.25em;
    input[type='search'] {
      font-size: 1em;
      display: inline-block;
      width: 100%;
      font-weight: normal;
    }
    button[type='submit'] {
      position: absolute;
      right: 0;
      display: inline-block;
      font-size: 0.6em;
      vertical-align: bottom;
    }
    .ep-autosuggest-container {
      display: inline-block;
      width: 100%;
      .ep-autosuggest {
        display: none !important;
      }
    }
    .filters-selected {
      float: right;
    }
    .filter-selected {
      color: $blueGrayLight;
      cursor: pointer;
    }
  }
  .search-result {
    padding: 2em 3em 2em 0;
    border-bottom: 1px solid $bluePrimary;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
    h3 {
      @include typeHeaderSearchResult;
      .ep-highlight {
        background: none;
        display: inline;
      }
    }
    p {
      margin-bottom: 0;
    }
    a {
      &:hover {
        text-decoration: none;
        color: inherit;
        ~ .icon-arrow {
          display: block;
        }
      }
    }
    .icon-arrow {
      display: none;
      position: absolute;
      font-size: 2em;
      right: 0;
      top: 50%;
      line-height: 1em;
      transform: translateY(-50%);
    }
  }
  .search-additional-fields {
    position: absolute;
    left: calc(100% + 80px);
    top: 0;
    height: 100%;
    vertical-align: middle;
    width: 200%;
    display: none;
    > * {
      display: inline-block;
      flex: auto;
      padding-right: 40px;
      padding-top: 0.5em;
    }
    .search-filter {
      &:after {
        content: '\25be';
        display: inline-block;
        margin: 0 0 0 0.5em;
      }
    }
    select {
      border: none;
      color: $blueGrayLight;
      &.has-selected {
        color: $bluePrimary;
      }
    }
  }
}
