.block-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    display: block;
  }
  strong {
    @include typeStatisticValue;
    text-align: center;
  }
  p {
    margin-bottom: 0;
    width: 50%;
    margin-left: 25%;
    text-align: center;
  }
  @include media-breakpoint-down(md) {
    p {
      margin-left: 0;
      width: 100%;
    }
  }
}
