.block-contact {
  h3 {
    font-size: 1em;
    margin: 0;
    font-weight: bold;
  }
  a {
    text-decoration: underline;
  }
}

.address__field:empty {
  display: none;
}

.contacts-list {
  .block-contact {
    margin-bottom: 1.2em;
  }
}
