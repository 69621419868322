.post-type-successstory {
  .post-intro,
  .post-intro-img {
    max-width: none;
  }
  .post-intro {
    padding: 0;
    .container {
      position: relative;
    }
  }
  .post-intro-img {
    width: 48%;
    position: absolute;
    padding: 0;
    right: 0;
    top: -15.75em;
    z-index: 3;
    img {
      top: 0;
      bottom: auto;
    }
  }
  .post-subsection {
    background: $white;
    padding-top: 0;
    > .container {
      > .row {
        flex-wrap: nowrap;
      }
    }
    .post-subsection__item {
      max-width: none;
    }
  }
  .post-subsection__content {
    width: calc(66.666% + ((100vw - #{$contentColumn}) / 2));
    padding-right: 18%;
    h2 {
      margin-bottom: 0;
    }
    .bug-division {
      margin: 1em 1em 2em 0;
      display: inline-block;
    }
  }
  .post-subsection__secondary {
    background: $bluePrimary;
    width: calc(33.333% + ((100vw - #{$contentColumn}) / 2));
    blockquote {
      padding: 0;
      margin: 0;
      margin-top: 150%;
      @include typeHeader;
      @include typeContrast;
      &:before {
        left: -0.25em;
        @include typeContrast;
      }
    }
  }
  @media screen and (max-width: $contentColumnWithGutter) {
    .post-subsection__content {
      width: 66.666%;
    }
    .post-subsection__secondary {
      width: 33.333%;
    }
  }
  @include media-breakpoint-down(lg) {
    .post-subsection__content {
      order: 1;
    }
    .post-subsection__secondary {
      order: 2;
      blockquote {
        &:before {
          left: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .post-subsection {
      > .container {
        > .row {
          flex-wrap: wrap;
        }
      }
    }
    .post-intro-img {
      top: 14em;
      left: $gutter;
      width: 360px;
    }
    .post-subsection__content,
    .post-subsection__secondary {
      width: 100%;
      padding-left: $gutter;
      padding-right: $gutter;
    }
    .post-subsection__content {
      h2,
      .bug-division {
        + p {
          margin-top: 29em;
        }
      }
    }
    .post-subsection__secondary {
      padding: em(35) $gutter em(70);
      blockquote {
        margin-top: em(70);
      }
    }
  }
}
