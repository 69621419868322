@mixin block-content-inset {
  width: 36%;
  margin-left: 7.25em;
  position: relative;
  &:before {
    content: '';
    width: 3em;
    height: 4px;
    background: $white;
    position: absolute;
    top: 0.5em;
    left: -7.25em;
    display: block;
  }
  @include media-breakpoint-down(md) {
    width: calc(100% - 7.25em);
    padding-right: $gutter;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-left: 0;
    padding: 4em 0 0;
    &:before {
      top: 2em;
      left: 0;
    }
  }
}

.wp-block-bushwick {
  position: relative;
  z-index: 2;
  margin-top: -100vh;
  height: 300vh;
  @extend .container;
  .wp-block-group__inner-container {
    @include typeContrast;
    @extend .row;
    @extend %breakout;
    height: 100vh;
    display: flex;
    align-items: center;
    align-content: center;
    background: $bluePrimary;
    padding: #{2 * $gutter} calc((100vw - #{$contentColumn}) / 2) em(70);
    opacity: 0;
    transition: opacity 0.3s ease-in;
    &.active {
      opacity: 1;
      position: absolute;
      bottom: 0;
      h2 {
        &,
        &:before,
        ~ p {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    &.sticky {
      position: fixed;
      bottom: auto;
      top: 0;
    }
    @media screen and (max-width: $contentColumnWithGutter) {
      padding-left: $gutter;
      padding-right: $gutter;
      margin-left: #{-1 * $gutter};
    }
  }
  h2 {
    @include typeHeaderSuperReduced;
    @include typeContrast;
    margin-bottom: 1em;
    &.page-section-header {
      @include typeHeaderSuperEm;
      @include typeContrast;
      margin-bottom: 0.65em;
      @media screen and (max-width: $contentColumnWithGutter) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &,
    &:before,
    ~ p {
      opacity: 0;
      transform: translateY(50%);
      transition: all 0.3s ease-in-out 0.3s;
    }
    &:before {
      font-size: 0.325em;
      position: static;
      display: block;
      transition-delay: 0s;
    }
    ~ p {
      transition-delay: 0.6s;
      @include block-content-inset;
      margin-top: 0;
    }
  }
}

.wp-block-bab-theme {
  @include typeContrast;
  background: $blueGrayLight;
  transition: background 1s ease-in-out;
  min-height: em(434);
  position: relative;
  display: flex;
  //&.initialized {
  &.wp-block-bab-theme-youth {
    background: $greenPrimary;
  }
  &.wp-block-bab-theme-seniors {
    background: $purplePrimary;
  }
  &.wp-block-bab-theme-tenants {
    background: $blueLight;
  }
  h3 {
    @include typeHeader;
    @include typeHeaderTight;
    @include typeContrast;
    width: 200%;
  }
  figure {
    position: absolute;
    right: calc((100vw - #{$contentColumn}) / 2);
    bottom: 0;
    width: 29.5%;
    height: 100%;
    margin: 0;
    padding: 0;
    img {
      height: auto;
      display: block;
      max-height: calc(100% - 4.5em);
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }
    @media screen and (max-width: $contentColumnWithGutter) {
      right: $gutter;
    }
    @include media-breakpoint-down(md) {
      position: relative;
      right: 0;
      margin-left: 7.25em;
      margin-top: 5em;
      img {
        position: static;
        width: auto;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      width: 100%;
      margin-top: 2em;
    }
  }
  .wp-block-group__inner-container {
    padding: em(70) calc((100vw - #{$contentColumn}) / 2) #{2 * $gutter};
    position: relative;
    height: auto;
    flex: 1 1;
    @media screen and (max-width: $contentColumnWithGutter) {
      padding-left: $gutter;
      padding-right: $gutter;
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: 0;
    }
  }
  .bab-theme__intro {
    @include typeDefaultLarge;
    @include typeContrast;
    width: 80%;
    margin-bottom: 1.5em;
  }
  .bab-theme__content {
    @include block-content-inset;
    &:before {
      top: 1.2em;
    }
  }
  .read-more {
    a {
      @include typeDefaultLarge;
      @include typeLinkContrast;
      font-weight: bold;
      color: $white;
      @include arrowLink;
    }
  }
}
