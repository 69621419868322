.embed-leap-timeline {
  width: 100%;
  padding-top: 170%;
  position: relative;
  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}
