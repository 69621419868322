// global styles here, such as basic typography, sizing, display styles, etc

body,
html {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

body {
  @include typeDefault;
}

.page-content {
  h2 {
    @include typeHeader;
  }

  a {
    @include typeLink;
  }
  blockquote {
    @include typeDefaultLarge;
    padding: 0 5em;
    background: none;
    margin: 4em 0 2em;
    line-height: 1.52em;
    position: relative;
    font-weight: bold;
    &:before {
      position: absolute;
      top: -0.275em;
      left: 0;
      opacity: 0.15;
      content: '“';
      font-size: 10em;
      line-height: 1em;
      font-family: Arial, sans-serif;
    }
    @include media-breakpoint-down(md) {
      @include typeDefault;
      font-weight: bold;
      padding: 0 2em;
    }
  }
}
