.post-type-staff {
  .post-subsection__item {
    &.post-subsection__secondary {
      &:empty {
        display: block;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }
}
