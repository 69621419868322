.post-type-audience {
  .page-content {
    padding: em(80) 0 0;
  }
}

.audience-issues {
  padding-bottom: em(80);
}

.audience-issue {
  border-bottom: 1px solid $bluePrimary;
  padding: em(41.6) 0;
  &:last-child {
    border-bottom: none;
  }
  &.audience-issue-other {
    h2 {
      &:before {
        background-color: $blueGray;
      }
      a {
        color: $blueGray;
      }
    }
    button {
      border-color: $blueGray;
      color: $blueGray;
      i {
        background: $blueGray;
      }
    }
  }
  article {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2 {
    @include typeHeaderSearchResult;
    margin: 0;
    padding: 0 0 0 3em;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: -0.25em;
    }
  }
  button {
    @include typeHeaderSearchResult;
    width: 9.75em;
  }
  @include media-breakpoint-down(sm) {
    article {
      flex-direction: column;
      align-items: flex-start;
    }
    h2 {
      margin-bottom: 2em;
      + a {
        display: flex;
        width: 100%;
        button {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}
